<template>
  <iframe id="iframe" frameborder="0" @load="sendMessage" allowfullscreen="true"
    :src="url" scrolling="yes" ref="iframe" ></iframe>
</template>
<script>
import { clearLoginInfo } from '@/utils'
export default {
  data () {
    return {
      organizationId:'',
      club:'',
      url: '',
      appId: '',
      code: '',
      iframeWindow: null,
      activityId: null,
      way: '',
      clubisRegister:'',
      clubSignUpTime:'',
      clubApplyWay:'',
      clubType:'',
      clubTitle:'',
    }
  },
  created () {
    console.log('appSSO',this.$route.query);
    console.log(this.$appId)
    if(this.$route.query.club){
      this.club= this.$route.query.club
    }
    if (this.$route.query.clubTitle) {
      this.clubTitle=this.$route.query.clubTitle
    }
    if (this.$route.query.clubType) {
      this.clubType=this.$route.query.clubType
    }
    if (this.$route.query.clubApplyWay) {
      this.clubApplyWay=this.$route.query.clubApplyWay
    }
    if (this.$route.query.clubSignUpTime) {
      this.clubSignUpTime=this.$route.query.clubSignUpTime
    }
    if (this.$route.query.clubisRegister) {
      this.clubisRegister=this.$route.query.clubisRegister
    }
    if(this.$route.query.organizationId){
      this.organizationId= this.$route.query.organizationId
    }
    if (this.$appId){
      this.$curUrl = this.$route.query.url
      this.appId = this.$route.query.appId
      this.code = this.$route.query.appCode
      this.activityId = this.$route.query.id
      this.way = this.$route.query.way
    }else {
      if (this.$curUrl=='antigen-home') {
        this.appId = 'antigenTest'
      } else if (this.$curUrl=='nucleic-register') {
        this.appId = 'nucleicRegister'
      } else if(this.$curUrl == 'scanRegister') {
        this.appId = this.$code
      }else if(this.$curUrl == 'safetyCode') {
        this.appId = this.$route.query.appCode
      } else {
        this.appId = this.$appId
      }
    }

    this.getBackToken(this.appId)
    // this.url = process.env.VUE_APP_SPLIT_URL
    if(this.$curUrl == 'scanRegister') {
      // 留下公安
      this.url = process.env.VUE_APP_GAGL_URLS + '?url=' + this.$curUrl
    }else if(this.$curUrl == 'service') {
      // 社区服务
      this.url = process.env.VUE_APP_SQFW_URLS + '?url=' + this.$curUrl
    }else if(this.$curUrl == 'integral'){
    // 积分通
      this.url = process.env.VUE_APP_BASE_URLS + '?url=' + this.$curUrl
    } else if(this.$curUrl == 'voluntSuccess' || this.$curUrl == 'activitySignUp'){
      // 积分通
      this.url = process.env.VUE_APP_BASE_URLS + '?url=' + this.$curUrl
    }else if(this.$curUrl == 'safetyCode'){
      let params = '?params=';
      params += this.$qrcodeOptions + '&o='+this.$orgId + '&domain='+
          this.$globalData.domain + '&p='+this.$openid + '&u='+this.$unionid + '&m=' + this.$purePhoneNumber
      this.url = 'https://sgg.hzxh.gov.cn/safetyCode-h5'+params
    }
    else {
      // 应用
      this.url = this.$curUrl
    }
    window.addEventListener('message', event => {
      if (event.data == 'close') {
        this.$router.push('/myApp')
      } else if (event.data.name == 'wxDownload') {
        wx.miniProgram.navigateTo({
          url: '/pages/web/pdfDownload?path=' + event.data.path
        })
      } else if (event.data.handle && event.data.handle == 'return') {
        let paramStr = ''
        if (event.data.query && event.data.query.name) {
          paramStr += 'name=' + event.data.query.name
        }
        wx.miniProgram.redirectTo({
          url: event.data.url + '?' + paramStr
        })
      }else if (event.data == 'toLogin'){
        wx.miniProgram.navigateTo({
          url: '/pages/login/login'
        })
      }else if (event.data.name == 'club') {
          wx.miniProgram.navigateTo({  //直接分享
            url: '/pages/club/share?id=' + event.data.query.id
          })

      }
    })
    this.andRndParam()
  },
  mounted () {
    this.iframeWindow = this.$refs.iframe.contentWindow
    // appToken验证失败时跳到登录页
    window.addEventListener('message', (event) => {
      if (event.data == 401) {
        clearLoginInfo()
        this.$message.error('token验证失败，请重新登录')
        this.$router.push({ name: 'login' })
      }
      // 应用h5页面跳过来转跳小程序
      // if (event.data.indexOf('pages') == 0) {
      //   wx.miniProgram.navigateTo({
      //     url: '/' + event.data
      //   })
      // }
    })
  },
  methods: {
     getBackToken () {
       console.log(this.$orgId)
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/jmgg/appauth'),
        method: 'get',
        params: this.$http.adornParams({
          appId: this.appId,
          orgId:this.$orgId,
          appCode: this.code
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$globalData.backToken = data.token
        } else {
          this.$message.error(data.msg)
          this.errorMsg = data.msg
        }
      })
    },
    // sendMessage () {
    //   this.iframeWindow.postMessage({
    //     orgId: this.$orgId,
    //     code: this.$code,
    //     isJM: true
    //   }, '*')
    // },
    sendMessage () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/jmgg/appToken'),
        method: 'get',
        params: this.$http.adornParams({
        appId: this.appId,
        orgId:this.$orgId,
        appCode: this.code
        })
      }).then(({data}) => {
        // console.log(window.__wxjs_environment, window.__wxjs_environment);
        let windowType = window.__wxjs_environment
        if (data && data.code === 0) {
          // 把appToken传给iframe
          this.iframeWindow.postMessage({
            organizationId:this.organizationId||'',
            clubisRegister:this.clubisRegister||'',
            clubSignUpTime:this.clubSignUpTime||'',
            clubApplyWay:this.clubApplyWay||'',
            clubType:this.clubType||'',
            clubTitle:this.clubTitle||'',
            club:this.club||'',
            solveStatus:this.$solveStatus,
            domain: this.$globalData.domain,
            appToken: data.appToken,
            token: this.$cookies.get('token')|| sessionStorage.getItem('token'),
            appid: this.$appid,
            appId: this.appId,
            orgId: this.$orgId,
            code: this.code,
            orgUserId: this.$userId,
            street: this.$street,
            userName: this.$userName ,
            mobile: this.$globalData.userInfo.mobile,
            county: this.$county,
            windowType: windowType,
            codeS:this.$route.query.codeS,
            open: this.$open,
            subarea: this.$subarea,
            subareaName: this.$subareaName,
            depth: 5,
            address: this.$address,
            userType: 1,
            placeId: this.$placeId,
            placeName: this.$placeName,
            placeOrgId: this.$placeOrgId,
            volunteerIds: this.$globalData.volunteerIds,
            communityId: this.$communityId,
            communityName: this.$communityName,
            buildingId: this.$buildingId,
            buildingName: this.$buildingName,
            qrcodeDepth: this.$qrcodeDepth,
            qrcodeId: this.$qrcodeId,
            unitId: this.$unitId,
            unitName: this.$unitName,
            houseId: this.$houseId,
            houseName: this.$houseName,
            age: this.$age,
            sex: this.$sex,
            labelIds: this.$labelIds,
            isOld: this.$isOld,
            activityId: this.activityId,
            info:data.info,
            way: this.way
          }, '*')
        } else {
          this.$message.error(data.msg)
          this.errorMsg = data.msg
        }
      })
    },
        /**
     * 添加时间戳参数去除缓存
     */
    andRndParam () {
      if (this.url.indexOf('t=') === -1) {
        if (this.url.indexOf('?') === -1) {
          this.url += '?t=' + new Date().getTime()
        } else {
          this.url += '&t=' + new Date().getTime()
        }
      }
    }
  }
}
</script>
<style scoped>
iframe {
  display: block; /* iframes are inline by default */
  border: none; /* Reset default border */
  height: 100vh !important; /* Viewport-relative units */
  width: 100vw !important;
}
</style>
